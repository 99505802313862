.edit-btns-user-wrap {
    display: flex;
    align-content: center;
    justify-content: flex-end;

    padding-top: 16px;
}

.edit-btns-user-wrap .purple-border-button {
    margin-right: 16px;
}

.addCategoryBtnWrap {
    display: flex;
    justify-content: flex-end;
}

.RaList-main {
    min-width: 1160px;
}

.customTableWrap .MuiCard-root {
    background: #FFFFFF;
    box-shadow: 0 2px 2px rgba(79, 71, 120, 0.16);
    border-radius: 8px 8px 0 0;
    padding: 18px;
    margin-top: 10px;
}

.MuiTablePagination-root {
    box-shadow: 0 2px 2px rgba(79, 71, 120, 0.16);
    border-radius: 0 0 8px 8px;
    margin-top: -2px;
}

.customTableWrap .MuiTablePagination-root .MuiToolbar-root {
    background: #FFFFFF;
}

.actionsWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.actionsWrap .MuiFormControl-root {
    background: #EFEFEF;
    border-radius: 4px 4px 0 0;
}

.actionsWrap .MuiFormControl-root label {
    padding: 0 12px;
}

.actionsWrap .MuiFormControl-root input {
    padding: 7px 12px;
}

.actionsWrap .MuiInput-underline:before {
    content: 'none'
}

thead {
    background: unset;
    box-shadow: unset;
    border-radius: unset;
}

.customTable th span {
    font-weight: 500;
}

.customTable th, .customTable td {
    padding: 6px 8px;
    word-break: break-word;
}

.customTable.products-table td:nth-child(1),
.customTable.files-table td:nth-child(1) {
    max-width: 120px;
    min-width: 120px;
}

.customTable.categories-table td:nth-child(1),
.customTable.products-table td:nth-child(2),
.customTable.files-table td:nth-child(2) {
    max-width: 233px;
    min-width: 196px;
}

.customTable.categories-table td:nth-child(1) span,
.customTable.products-table td:nth-child(2) span,
.customTable.files-table td:nth-child(2) span {
    display: block;
    white-space: nowrap;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.customTable.categories-table td:nth-child(2),
.customTable.products-table td:nth-child(3),
.customTable.files-table td:nth-child(3) {
    max-width: 600px;
    min-width: 250px;
}

.customTable.categories-table th:nth-child(3) span,
.customTable.products-table td:nth-child(3) span,
.customTable.products-table td:nth-child(4) span,
.customTable.files-table td:nth-child(3) span,
.customTable.files-table th:nth-child(4) span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.customTable.categories-table td:nth-child(2) span,
.customTable.products-table td:nth-child(3) span,
.customTable.files-table td:nth-child(3) span {
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.customTable.categories-table th:nth-child(3), .customTable.categories-table td:nth-child(3) {
    min-width: 178px;
}

.customTable.categories-table th:nth-child(4), .customTable.categories-table td:nth-child(4), .customTable.categories-table th:nth-child(5), .customTable.categories-table td:nth-child(5) {
    min-width: 100px;
}

.customTable.categories-table td:nth-child(7) div,
.customTable.files-table td:nth-child(8) div,
.customTable.products-table td:nth-child(9) div  {
    justify-content: flex-end;
}

.customTable th .MuiButton-root, .customTable td .MuiButton-root {
    min-width: unset;
}

.customTable.products-table th:nth-child(4), .customTable.products-table td:nth-child(4) {
    min-width: 70px;
}

.customTable.products-table th:nth-child(5), .customTable.products-table td:nth-child(5) {
    min-width: 125px;
}

.customTable.products-table th:nth-child(6), .customTable.products-table td:nth-child(6) {
    min-width: 116px;
}

.customTable.products-table th:nth-child(7), .customTable.products-table td:nth-child(7) {
    min-width: 112px;
}

.customTable.products-table th:nth-child(8), .customTable.products-table td:nth-child(8) {
    min-width: 124px;
}

.customTable.files-table th:nth-child(4), .customTable.files-table td:nth-child(4) {
    min-width: 178px;
}

.customTable.files-table td:nth-child(5),
.customTable.files-table td:nth-child(6),
.customTable.files-table td:nth-child(7) {
    min-width: 100px;
}

.dailyTitle {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #4A1D52;
}

.dailyTitle + .create-page .MuiCard-root {
    overflow: visible;
}

.MuiTypography-root .formFields {
    max-width: 480px;
    min-width: 480px;
    width: 100%;
}

.tabbedForm a.MuiButtonBase-root {
    text-transform: unset;
}

.tabbedFormWrap {
    max-width: 480px;
    width: 100%;
}

.uploadImageLabel {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(48, 51, 54, 0.64);
    margin: 0 0 16px;
}

.imageWrap {
    max-width: 154px;
    width: 100%;
    margin: 0 0 16px;
}

.imageWrap img {
    max-width: 100%;
    max-height: 171px;
    margin: 0;
}

.dailyActionToolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    background: unset !important;
    box-sizing: border-box;
    padding: 0;
    margin-top: 40px;
}

.customerActionToolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    background: unset !important;
    box-sizing: border-box;
    padding: 24px;
}

.customer-form-wrap {
    max-width: 480px;
    width: 100%;
}

.booleanInputDescription {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(48, 51, 54, 0.64);
    margin: 0;

    word-break: break-word;
}

.booleanInputDescription.last {
    width: fit-content;
    margin: 0;
}

.booleanInput .MuiFormControlLabel-root {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 0;
    width: 256px;
}

.booleanInput .MuiFormControlLabel-root span.MuiFormControlLabel-label {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(48, 51, 54, 0.64);
}

.MuiTypography-root .numberInput {
    max-width: 480px;
    min-width: 480px;
    width: 100%;
}

.numberInput input {
    -webkit-appearance: none;
    margin: 0;
}

.priorityLabelsWrap {
    display: flex;
    align-items: center;
    width: 173px;
    margin-bottom: 16px;
}

.priorityLabelsWrap span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #7F8DA0;
}

.priorityLabelsWrap span:first-child {
    min-width: 125px;
    width: 125px;
    margin-right: 8px;
}

.emptyProducts {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
}

.change-password-form__inputs-wrap {
    position: relative;
    width: 100%;
}

.passwords-error {
    font-family: 'Roboto', sens-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin: 4px 14px 0;
    color: #f44336;
    position: absolute;
    bottom: -8px;
}

.confirm-password-error .MuiInputBase-root.MuiFilledInput-root:before,
.confirm-password-error .MuiInputBase-root.MuiFilledInput-root:after {
    border-bottom-color: #f44336;
}

.confirm-password-error span {
    color: #f44336;
}

.edit-customer .MuiToolbar-root.MuiToolbar-gutters {
    display: none;
}

.pt-32 {
    padding-top: 32px;
}

.edit-user-btns button {
    padding: 8px 16px;
}