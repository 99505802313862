@import "_buttons.css";
@import "_daily-dare-general.css";
@import "_daily-dare-localizations.css";
@import "_localization-action-buttons.css";
@import "_content.css";
@import "_home-blocks.css";

* {
    letter-spacing: 0.25px;
}

body {
    margin: 0;
}

.MuiDrawer-root {
    z-index: 9;
    position: sticky;
    position: -webkit-sticky;
    top: 50px;
    left: 0;
    background: #fafafa;
    min-height: 500px;
    max-height: calc(100vh - 5em);
    padding-bottom: 10px;
}

@media (max-width: 599px) {
    .MuiDrawer-root {
        max-height: 100%;
    }

    .MuiListItemIcon-root {
        display: flex;
        align-items: center;
    }

    .MuiPaper-root.MuiDrawer-paper .MuiBox-root .MuiListItem-button:last-child {
        margin-top: auto;
    }
}

header {
    background-image: linear-gradient(90deg, #8F70C0 0%, #492584 100%);
    color: #fff;
    background-color: unset;
}

header span {
    color: #fff;
}

header button svg.RaSidebarToggleButton-menuButtonIconOpen,
header button svg.RaSidebarToggleButton-menuButtonIconClosed {
    fill: #fff;
}

header .MuiCircularProgress-root,
header .RaLoadingIndicator-loadedIcon {
    display: none !important;
}

header .MuiToolbar-root {
    background: unset;
}

.MuiDrawer-root.MuiDrawer-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999
}

.MuiDrawer-root .MuiPaper-root div {
    position: static;
    height: 100%;
}

.MuiDrawer-root.MuiDrawer-modal .MuiBox-root {
    position: static;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.MuiDrawer-root .MuiPaper-root div .MuiBox-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0
}

.MuiDrawer-root .MuiPaper-root div .MuiBox-root .logout {
    margin-top: auto
}

.simple-form .async-select {
    width: 100%;
    max-width: 480px;
    margin-bottom: 20px;
    z-index: 2;
}

.form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    width: 100%;
}

.MuiTabs-root .show-tab {
    text-transform: unset;
}

.async-select-wrap {
    position: relative;
    z-index: 99;
    margin-bottom: 20px;
}

.localization-field-wrap,
.source-field-wrap,
.async-select {
    width: 100%;
}

.localization-field-wrap.error .MuiFormLabel-root,
.source-field-wrap.error .MuiFormLabel-root,
.custom-datepicker-wrap.error .custom-datepicker-field .MuiFormLabel-root,
.form-field.name.error .MuiFormLabel-root,
.form-field.link.error .MuiFormLabel-root,
.form-field.name-required.error .MuiFormLabel-root,
.form-field.link-required.error .MuiFormLabel-root,
.form-field.member_link.error .MuiFormLabel-root,
.form-field.sales_link.error .MuiFormLabel-root,
.form-field.description.error .MuiFormLabel-root,
.name.error .MuiFormLabel-root,
.name-required.error .MuiFormLabel-root,
.member_link.error .MuiFormLabel-root,
.sales_link.error .MuiFormLabel-root,
.image-required.error .fieldTitle,
.horizontal_image-required.error .fieldTitle,
.name-required.error .fieldTitle,
.member_image-required.error .fieldTitle,
.sales_image-required.error .fieldTitle,
.order.error .MuiFormLabel-root {
    color: #f44336;
}

.localization-field-wrap.error .MuiFilledInput-underline:after,
.source-field-wrap.error .MuiFilledInput-underline:after,
.custom-datepicker-wrap.error .custom-datepicker-field .MuiFilledInput-underline:after,
.form-field.name.error .MuiFilledInput-underline:after,
.form-field.link.error .MuiFilledInput-underline:after,
.form-field.member_link.error .MuiFilledInput-underline:after,
.form-field.sales_link.error .MuiFilledInput-underline:after,
.form-field.description.error .MuiFilledInput-underline:after,
.name.error .MuiFilledInput-underline:after,
.member_link.error .MuiFilledInput-underline:after,
.sales_link.error .MuiFilledInput-underline:after,
.order.error .MuiFilledInput-underline:after {
    transform: scaleX(1);
    border-bottom-color: #f44336;
}

.async-select.error div {
    border-color: #f44336;
    color: #f44336;
}

.image-required.error .uploadButton,
.horizontal_image-required.error .uploadButton,
.name-required.error .uploadButton,
.member_image-required.error .uploadButton,
.sales_image-required.error .uploadButton,
.async-select.error span {
    background-color: #f44336;
}

.async-select.error svg {
    fill: #f44336;
}

.required-text {
    display: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    color: #f44336;
    text-align: left;
    margin: 4px 14px 0 14px;
}

.form-field.name.error .MuiFormHelperText-root,
.form-field.link.error .MuiFormHelperText-root,
.form-field.member_link.error .MuiFormHelperText-root,
.form-field.sales_link.error .MuiFormHelperText-root,
.form-field.description.error .MuiFormHelperText-root,
.name.error .MuiFormHelperText-root,
.member_link.error .MuiFormHelperText-root,
.sales_link.error .MuiFormHelperText-root,
.order.error .MuiFormHelperText-root {
    display: none;
}

.localization-field-wrap.error .required-text,
.source-field-wrap.error .required-text,
.custom-datepicker-wrap.error .required-text,
.form-field.member_link.error + .required-text,
.form-field.sales_link.error + .required-text,
.form-field.name.error + .required-text,
.form-field.link.error + .required-text,
.form-field.name-required.error + .required-text,
.form-field.link-required.error + .required-text,
.form-field.description.error + .required-text,
.name.error + .required-text,
.name-required.error + .required-text,
.member_link.error + .required-text,
.sales_link.error + .required-text,
.order.error + .required-text,
.image-required.error .required-text,
.horizontal_image-required.error .required-text,
.name-required.error .required-text,
.member_image-required.error .required-text,
.sales_image-required.error .required-text,
.async-select.error + .required-text {
    display: block;
}

.localization-field-wrap.error,
.source-field-wrap.error,
.custom-datepicker-wrap.error {
    position: relative;
}

.localization-field-wrap.error .required-text,
.source-field-wrap.error .required-text,
.custom-datepicker-wrap.error .required-text {
    position: absolute;
    bottom: 5px;
    left: 0;
}

.error-field label {
    background: #f44336;
}

.error-field input::placeholder {
    color: #f44336;
}

.errorText {
    color: #f44336;
    margin: 4px 14px 12px;
    font-size: 0.75rem;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}

.RaSidebar-fixed li {
    margin-top: auto;
    color: rgba(0, 0, 0, 0.54);
}

@media (max-width: 992px) {
    .MuiDrawer-root.MuiDrawer-modal.RaSidebar-modal {
        background: unset;
    }

    .MuiDrawer-root.MuiDrawer-modal.RaSidebar-modal .MuiPaper-root {
        position: fixed;
        background-color: #fff;
    }

    .MuiDrawer-root.MuiDrawer-modal.RaSidebar-modal .MuiPaper-root li {
        margin-top: auto;
        height: 48px;
        color: rgba(0, 0, 0, 0.54);
    }
}

.logout-icon-wrap {
    color: rgba(0, 0, 0, 0.54);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 40px;
    position: static;
    height: 100%;
}

.logout-icon {
    fill: rgba(0, 0, 0, 0.54);
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.RaList-main > .MuiToolbar-root {
    min-height: 88px;
    display: flex;
    align-items: center;
}

.RaTabbedShowLayout-content {
    padding: 8px 16px;
}

.showDatelabel {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(48, 51, 54, 0.64);
    margin-bottom: 22px;
}

.category-table-item {
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 109px;
    white-space: nowrap;
    overflow: hidden;
}

.product-table-item {
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 204px;
    white-space: nowrap;
    overflow: hidden;
}

.files-localization-form {
    max-width: 480px;
    width: 100%;
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(18, 18, 18, 0.4);
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader svg circle {
    color: #8F70C0;
}

.custom-datepicker-wrap,
.custom-datepicker-wrap .MuiTypography-root {
    width: 100%;
    position: relative;
}

.custom-datepicker {
    display: flex;
    align-items: center;
}

.picker {
    position: absolute;
    right: 13px;
    cursor: pointer;
}

.custom-datepicker label.Mui-disabled {
    color: rgba(0, 0, 0, 0.54);
}

.custom-datepicker .MuiFilledInput-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(0, 0, 0, 0.04);
}

.custom-datepicker .MuiFilledInput-root.Mui-disabled:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.custom-datepicker input.Mui-disabled {
    -webkit-text-fill-color: currentColor;
    color: currentColor;
}

.custom-datepicker-wrap .rmdp-container,
.custom-datepicker-wrap .rmdp-container input {
    position: absolute;
    width: 0;
    height: 0;
    border: unset;
    padding: 0;
    margin: 0;
}

.custom-datepicker-wrap .ep-arrow.rmdp-ep-arrow.rmdp-ep-shadow {
    display: none !important;
}

.RaLayout-contentWithSidebar .MuiPaper-root.MuiPaper-elevation.MuiCard-root {
    overflow: visible;
}

.MuiDrawer-root.MuiDrawer-modal.RaSidebar-modal .MuiPaper-root,
.MuiDrawer-root.MuiDrawer-docked.RaSidebar-docked .MuiPaper-root.RaSidebar-paper {
    max-width: 16rem;
    min-width: 10rem;
    width: fit-content;
}

.MuiDrawer-root.MuiDrawer-modal.RaSidebar-modal .MuiPaper-root .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root,
.MuiDrawer-root.MuiDrawer-docked.RaSidebar-docked .MuiPaper-root.RaSidebar-paper .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root {
    word-break: break-word;
    white-space: normal;
}

.MuiDrawer-root .MuiPaper-root .multilevel-menu,
.MuiDrawer-root .MuiPaper-root .multilevel-menu * {
    height: fit-content;
}

.MuiDrawer-root .MuiPaper-root .multilevel-menu .multilevel-menu__items {
    height: 0;
    opacity: 0;
    overflow: hidden;
    margin: 0;
    padding: 0 0 0 16px;
    list-style: none;
}

.MuiDrawer-root .MuiPaper-root .multilevel-menu .multilevel-menu__items.open {
    height: fit-content;
    opacity: 1;
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.MuiDrawer-root .MuiPaper-root .multilevel-menu .multilevel-menu__items li {
    height: fit-content;
}

.MuiDrawer-root .MuiPaper-root .multilevel-menu .multilevel-menu__title {
    position: relative;
}

.MuiDrawer-root .MuiPaper-root .multilevel-menu .multilevel-menu__title p {
    font-family: 'Roboto',sens-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.54);

    margin: 0;
    padding: 6px 16px;

    cursor: pointer;
}

.MuiDrawer-root .MuiPaper-root .multilevel-menu .multilevel-menu__title p:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.MuiDrawer-root .MuiPaper-root .multilevel-menu .multilevel-menu__title.active a {
    color: rgba(0, 0, 0, 0.87);
}

.MuiDrawer-root .MuiPaper-root .multilevel-menu .multilevel-menu__title.active p {
    color: rgba(0, 0, 0, 0.87);
}

.MuiDrawer-root .MuiPaper-root .multilevel-menu .multilevel-menu__title .multilevel-menu__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;

    display: flex;
    align-items: center;
    pointer-events: none;
    z-index: 0;
}

.MuiDrawer-root .MuiPaper-root .multilevel-menu .multilevel-menu__title .multilevel-menu__icon.active {
    transform: translateY(-50%) rotate(180deg);
}

.MuiDrawer-root .MuiPaper-root .multilevel-menu .multilevel-menu__title.active .multilevel-menu__icon svg {
    fill: rgba(0, 0, 0, 0.87);
}

.audio-plug,
.video-plug {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
}

.orders-form {
    display: flex;
    align-items: center;
}

.orders-form p {
    display: none;
}

.orders-form .booleanInput .MuiFormControlLabel-root {
    margin-left: 24px;
    width: 120px;
}

@media (max-width: 1024px) {
    .orders-form .booleanInput .MuiFormControlLabel-root {
        margin-top: 8px;
        margin-left: 0;
    }
}

.m-0 {
    margin: 0 !important;
}

.group-categories {
    min-width: 480px;
}

.group-categories__item {
    display: flex;
    align-items: flex-start;
}

.group-categories__item-title {
    color: #7F8DA0;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
}

.group-categories__item-select {
    width: 100%;
}

.group-categories__item-select-wrap .MuiFormHelperText-root {
    display: none;
}

.group-categories__item-select-wrap {
    position: relative;
    width: 100%;
    margin-right: 60px;
}

.group-categories__item-select-delete {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -30px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;

    border: 0;
    outline: none;
    padding: 0;

    background: unset;

    cursor: pointer;
}

.group-categories__item-select-delete * {
    cursor: pointer;
}

.group-categories__item-select-delete:hover {
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
}

.group-categories__item-select-titles,
.group-categories__item-select-container {
    display: flex;
    align-items: center;
}

.group-categories__item-select-container {
    position: relative;
    margin-bottom: 24px;
}

.priority-error {
    position: absolute;
    left: 0;
    bottom: -21px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    color: #f44336;
    text-align: left;
    margin: 4px 14px 0 14px;
}

.group-categories__item-select-titles p:first-child {
    width: 100%;
    margin-right: 60px;
}

.group-categories__item-select-priority {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.group-categories__item-select-priority input {
    outline: none;
    background: unset;
    border: 0;
    color: #25232B;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;

    width: 48px;
    height: 32px;

    padding: 6px;

    border-radius: 6px;
    background: #F8F2FF;
}

.group-categories__item-select-priority input::-webkit-inner-spin-button,
.group-categories__item-select-priority input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}