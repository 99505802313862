.home-blocks__title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #4A1D52;
}

.home-blocks__wrap {
    padding: 16px 16px 24px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
}


@media (min-width: 600px) {
    .home-blocks__wrap {
        margin-top: 1em;
    }
}

.home-blocks__item {
    max-width: 100%;
    width: 100%;

    display: flex;
    align-items: center;

    margin-bottom: 16px;
}

.home-blocks__item p {
    width: 130px;
    margin-right: 16px;
}

.home-blocks__item input:not(.PrivateSwitchBase-input) {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    position: relative;
    cursor: text;
    align-items: center;
    width: 100px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    -webkit-transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    padding: 12px;
    background-color: rgba(0, 0, 0, 0.04);

    border-top: 0;
    border-left: 0;
    border-right: 0;

    margin-right: 16px;

    border-bottom: 2px solid rgba(0, 0, 0, 0.42);
}

.home-blocks__item input.title-input {
    max-width: 250px;
    width: 100%;
}

.home-blocks__item input:hover {
    background-color: rgba(0, 0, 0, 0.09);
    border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}

.home-blocks__item input:active, .home-blocks__item input:focus {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    outline: none;
    border-bottom: 2px solid #3f51b5;
}

.home-blocks__item input[type=number]::-webkit-inner-spin-button,
.home-blocks__item input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.home-blocks__item input[type=number] {
    -moz-appearance: textfield;
}

.home-blocks__item input.error {
    border-bottom-color: #f44336;
}

.home-blocks button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@media (max-width: 1024px) {
    .home-blocks__item {
        max-width: 100%;
        flex-wrap: wrap;
    }

    .home-blocks__item p {
        width: 100%;
        margin-right: 0;
        margin-bottom: 8px;
    }
}

@media (max-width: 500px) {
    .home-blocks__wrap {
        padding: 0;
        margin-bottom: 20px;
    }

    .home-blocks__item input.title-input {
        max-width: 115px;
        width: auto;
    }
}