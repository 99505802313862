.dailyLocalizationTitle {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #000000;
}

.fileField {
    margin: 0 0 20px 0;
}

.file-field-wrap {
    display: flex;
    align-items: flex-start;
    white-space: nowrap;
    margin: 0 0 16px;
}

.fieldTitle {
    display: block;
    width: fit-content;
    margin: 0 16px 0 0;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(48, 51, 54, 0.64);
}

.fileName {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #606C7D;
}

.fileName span{
    padding: 4px 16px;
    margin: 0 16px 0 0;
}

.fileInput {
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
}

.uploadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    width: fit-content;
    background: #8F70C0;
    border-radius: 4px;
    cursor: pointer;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #fff;
}

.uploadButton.download-btn {
    border: 0;
    margin-left: 20px;
    text-decoration: none;
}

.uploadButton.download-btn svg {
    margin-right: 8px;
}

.durationInput {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #606C7D;
    padding: 4px 16px;
    border: unset;
    outline: unset;
}

.imageLabel {
    height: 0
}

.image {
    max-width: 100%;
    max-height: 171px;
    margin-bottom: 16px;
}

.dailyDareActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0 56px;
    width: 100%;
}

.dailyDareActions .MuiToolbar-root.MuiToolbar-gutters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    background: unset;
    box-sizing: border-box;
    padding: 0
}

.file-field-wrap .fieldTitle {
    padding-top: 5px;
}

.file-field-wrap .fieldTitle + div {
    display: flex;
    align-items: flex-start !important;
    width: 100%;
}

.file-field-wrap .fileName span {
    padding: 4px 16px;
    margin: 0 16px 0 0;
    white-space: pre-line;
    word-break: break-word;
    display: block;
}

.file-field-wrap .uploadButton:not(.download-btn) {
    margin-left: auto;
}