.change-password-btns .button.purple-border-button {
    padding: 7px 16px;
    margin-right: 16px;
}

.button.create_btn {
    color: white;
    background: #4884EF;
    padding: 7px 18px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
}

.button.create_btn:hover {
    background-color: #4884EF;
}

a.button, button.button {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;

    white-space: nowrap;
    text-transform: unset;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    min-height: 32px;
    gap: 8px;

    border-radius: 4px;

    outline: none;

    cursor: pointer;
}

.button.gradient-button {
    color: #FFFFFF;
    background: linear-gradient(90deg, #8F70C0 0%, #492584 100%);
    border: none;
}

.button.gradient-button span {
    padding: 0
}

.purple-border-button {
    color: #8F70C0;
    background: unset;
    border: 1px solid #8F70C0;
}

.buttonsWrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btnsWrap {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.purple-button {
    color: #FFFFFF;
    background: #8F70C0;
    margin: 0 auto;
    border: none;
}

.red-border-button {
    color: #EB5757;
    margin-right: 16px;
    background: unset;
    width: 100%;
    border: 1px solid #EB5757;
}

.button.bar-gradient-button {
    color: #FFFFFF;
    background: linear-gradient(90deg, #8F70C0 0%, #492584 100%);
    padding: 8px 16px;
    border: none;
}

.button.bar-purple-button {
    color: #8F70C0;
    background: unset;
    border: 1px solid #8F70C0;
    padding: 7px 16px;
    margin: 0 16px;
}

.crud-mode {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.subscription-buttonsWrap {
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 100%;
}

.subscription-cancel-btn {
    margin-right: 20px;
}

@media (max-width: 550px) {
    .subscription-buttonsWrap {
        flex-direction: column;
    }

    .subscription-cancel-btn {
        margin-right: 0;
    }

    .subscription-buttonsWrap button {
        margin-bottom: 20px;
        margin-right: 0;
        max-width: 100%;
        width: 100%;
    }

    .btnsWrap {
        flex-direction: column;
        width: 100%;
    }
}