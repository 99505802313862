.localizationButtons {
    display: flex;
    align-items: center;
    margin-right: auto;
}

.localizationButtons .localizationButton {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #EB5757;

    white-space: nowrap;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    border: 1px solid #EB5757;
    border-radius: 4px;

    background: unset;
    cursor: pointer;

    text-transform: unset;
}

.localizationButtons .localizationButton span {
    padding: 0
}

.localizationButtons .localizationButton.delete {
    margin-right: 16px;
}

.localizationButtons .localizationButton.enable {
    border-color: #8F70C0;
    color: #8F70C0;
}