.dailyTitle {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #4A1D52;
}

.showDateLabel {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(48, 51, 54, 0.64);
    margin-bottom: 22px;
}

.localizationWrap form, .form-wrap {
    width: 100%;
}

.localization-form {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    width: 100%;
}

.form-wrap:first-child {
    margin-right: 20px;
}